import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Container, Image, Text } from "@atoms";
import { ArchFrame } from "@molecules";
import { m, AnimatePresence, useScroll, useTransform } from "framer-motion";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const StoriesOfChangeList = ({ list }) => {
  const [active, setActive] = useState(0);
  const lang = useLang();

  const outerProgress = useRef(null);

  const { scrollYProgress } = useScroll({
    target: outerProgress,
    offset: ["start start", "end end"],
  });

  const index = useTransform(scrollYProgress, [0, 1], [0, list.length]);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(v => {
      setActive(last =>
        Math.floor(last) === Math.floor(v * list.length)
          ? Math.floor(last)
          : Math.floor(index.get())
      );
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // stolen from https://stackoverflow.com/questions/1480133/how-can-i-get-an-objects-absolute-position-on-the-page-in-javascript
  const cumulativeOffset = el => {
    let top = 0;
    let left = 0;
    let element = el;

    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top,
      left,
    };
  };

  return (
    <Container>
      <div
        ref={outerProgress}
        className="my-10 flex flex-col items-start justify-between gap-10 md:my-20 md:flex-row"
        style={{ height: `${list.length}00vh` }}
      >
        <div className="sticky top-0 flex w-full items-center">
          <AnimatePresence initial={false} mode="popLayout">
            {list?.[active]?.uid && (
              <m.div
                key={list?.[active]?.uid}
                className="flex h-screen w-full flex-col items-center justify-center gap-10 sm:flex-row"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ ease: "backInOut", duration: 0.6 }}
              >
                {/* active arch */}
                <ArchFrame className="relative w-full max-w-[13.5rem] shrink-0 lg:max-w-xs">
                  <Image image={list[active].image} fill />
                </ArchFrame>
                {/* main story */}
                <div className="space-y-3 text-white sm:space-y-7">
                  <h3 className="leading-tighter font-magilio text-3xl sm:text-4xl md:text-5xl lg:text-h3">
                    {list?.[active]?.title}
                  </h3>
                  <Text variant="label" className="pb-2">
                    {list?.[active]?.subheading}
                  </Text>
                  <Text variant="sm" className="prose max-w-md">
                    {list?.[active]?.copy}
                  </Text>
                </div>
              </m.div>
            )}
          </AnimatePresence>
        </div>

        {!!list.length && (
          <ul className="top-0 hidden flex-row items-center justify-center gap-3 md:sticky md:flex md:h-screen md:flex-col">
            {list?.map((story, i) => (
              <li>
                <button
                  type="button"
                  onClick={() => {
                    const { top } = cumulativeOffset(outerProgress.current);
                    const position =
                      (outerProgress.current.clientHeight / list.length) * i +
                      1 +
                      top;
                    document.documentElement.scrollTo({
                      top: position,
                      behavior: "smooth",
                    });
                  }}
                  aria-label={`${t("About", lang)} ${story.title}`}
                >
                  <ArchFrame
                    className={classNames("w-10 transition-opacity", {
                      "opacity-40": i !== active,
                    })}
                  >
                    <Image image={story.image} fill />
                  </ArchFrame>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Container>
  );
};

StoriesOfChangeList.defaultProps = {};

export default StoriesOfChangeList;
